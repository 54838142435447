<template>
    <!-- <div :style="mainContentTopPadding"> -->
    <div>
        <slot name="override">
<!--            <CoreContainer tag="div" class="pb-8 pt-5 lg:pb-16">-->
<!--                <BaseContainerContent :padding="{ vertical: 'none' }">-->
<!--                    <UiBreadcrumbs-->
<!--                        :collapse-max-items="4"-->
<!--                        :items="page?.getBreadcrumbs() ?? breadcrumbs.items.value"-->
<!--                    />-->
<!--                </BaseContainerContent>-->
<!--            </CoreContainer>-->

            <slot name="override-main">
                <main id="main">
                    <slot />
                </main>
            </slot>
        </slot>
    </div>
</template>

<script lang="ts" setup>

const { syncedPage: page } = useCurrentPage()
const breadcrumbs = useBreadcrumbs()
// const mainContentTopPadding = useStateMainContentTopPadding()

</script>
